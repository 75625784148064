import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'controllers'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Our packages
import 'javascripts/user/turbolinks_prepare_for_cache'
import 'javascripts/shared/map_init'

// Vendors

import 'javascripts/shared/youtube'

import ApexCharts from 'apexcharts'
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'

import 'chartkick/chart.js'

hljs.registerLanguage('json', json)
window.ApexCharts = ApexCharts

ActiveStorage.start()

// Requires
require('trix')
require('@rails/actiontext')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true)
// const imagePath = (name) => images(name, true);

document.addEventListener('turbolinks:load', (event) => {
  hljs.highlightAll()
})
